
import { computed, defineComponent, onMounted, reactive, ref } from 'vue'
import dayjs, {
	convertToDate,
	convertToTime,
	formatDefault,
} from '@/utils/dayjs'
import { getReconcileOrder } from '@/api/report/report.api'
import { ReconcileOrderParams } from '@/api/report/type'
import { useRoute } from 'vue-router'
import { courierMap, COURIER } from '@/constants/courier'
import { autofitColumns } from '@/utils/xlsx'
import xlsx from 'xlsx'
import { mapCourierLinkTracking } from '@/utils/courier'
import { useShopStore } from '@/pinia/shop/shop.pinia'
import { useCourierStore } from '@/pinia/courier/courier.pinia'
import { useAnalytics } from '@/use/useAnalytics'
import { EVENT_NAME } from '@/constants/analytics'
import { useReactNativeEvent, FILE_TYPE } from '@/use/useReactNativeEvent'

export default defineComponent({
	name: 'ReportCODDetailView',
	setup() {
		const courierStore = useCourierStore()
		const shopStore = useShopStore()
		const route = useRoute()
		const { emitDownloadFile } = useReactNativeEvent()
		const { logEvent } = useAnalytics()
		const shopId = computed(() => shopStore.selectedShopId)
		const search = ref<string>('')
		const isLoading = ref<boolean>(false)
		const state = reactive({
			isLoading: false,
			limit: 999999,
			offset: 1,
			reconcile: {
				codTotal: 0,
				reconcileDate: null as null | string,
			},
			orderList: [] as any[],
			shop: null,
			isGenerateReport: false,
		})

		const fetchReconcileOrder = () => {
			state.isLoading = true
			const params: ReconcileOrderParams = {
				offset: state.offset,
				limit: state.limit,
				shopId: shopId.value as string,
				search: search.value,
				reconcileId: route.params.reconcileId as string,
			}
			getReconcileOrder(params)
				.then(({ data: { data, shop, reconcile } }) => {
					state.orderList = courierStore.mapCourierWithOrder({data}).data
					state.shop = shop
					state.reconcile = reconcile
				})
				.finally(() => (state.isLoading = false))
		}

		const displayName = (kycData: any) => {
			if (!kycData) return '-'
			if (kycData.type === 'ORGANIZATION') {
				return kycData.info?.companyName
			}
			return `${kycData.info?.firstname} ${kycData.info?.lastname}`
		}

		const exportReconcileCODReport = () => {
			//
			state.isGenerateReport = true

			const wsData = []
			const header = [
				'เลขคำสั่งซื้อ',
				'เลขพัสดุ',
				'ขนส่ง',
				'ข้อมูลลูกค้า',
				'ยอดเงิน',
				'รับพัสดุ',
				'ส่งมอบพัสดุ',
			]
			// Header
			wsData.push(header)
			// Data
			for (const row of state.orderList as any) {
				wsData.push([
					row.reference,
					row.trackingNumber,
					row.courier?.name || '-',
					row.recipientName ?? '-',
					row.cost ?? 0,
					formatDefault(row.pickupTime),
					formatDefault(row.destinationTime),
				])
			}
			wsData.push([]) // Empty Row
			wsData.push([
				'',
				'',
				'ยอดรวม COD',
				state.reconcile?.codTotal ?? 0,
				'',
				'',
			])

			const worksheet = xlsx.utils.aoa_to_sheet(wsData)
			const workbook = xlsx.utils.book_new()

			// width maximize column with
			worksheet['!cols'] = autofitColumns(wsData)
			/* Add the worksheet to the workbook */
			xlsx.utils.book_append_sheet(workbook, worksheet, 'SheetJs')

			const fileName = `reconcile-${convertToDate(
				state.reconcile?.reconcileDate
			)}.xlsx`

			if (window.ReactNativeWebView) {
				const excelBuffer = xlsx.write(workbook, { bookType: 'xlsx', type: 'base64' })

				emitDownloadFile(excelBuffer, FILE_TYPE.xlsx)

			} else {
				xlsx.writeFile(workbook, fileName)
			}

			state.isGenerateReport = false

			// logs
			logEvent({ eventName: EVENT_NAME.exportExcelCOD })
		}

		const onSearchOrderList = async () => {
			fetchReconcileOrder()
		}

		onMounted(() => {
			fetchReconcileOrder()
		})

		return {
			search,
			isLoading,
			state,
			onSearchOrderList,
			convertToDate,
			convertToTime,
			formatDefault,
			displayName,
			courierMap,
			COURIER,
			exportReconcileCODReport,
			mapCourierLinkTracking,
		}
	},
})
